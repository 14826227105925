module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Řeznictví & uzenářství Pelikán","titleTemplate":"%s | Řeznictví & uzenářství Pelikán","description":"Vlastní uzenářské výrobky z českého masa.","language":"cs","canonical":"https://www.reznictvi-menin.cz/","openGraph":{"title":"Řeznictví & uzenářství Pelikán","description":"Vlastní uzenářské výrobky z českého masa.","type":"website","locale":"cs","url":"https://www.reznictvi-menin.cz/"},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image","card":"summary"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Řeznictví & uzenářství Pelikán","start_url":"/","background_color":"#F4F4F4","theme_color":"#CB3F31","display":"minimal-ui","icon":"static/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"62e55a70bfb362bcb76d605b85e7b4db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
